import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { GoogleLogin } from "@react-oauth/google";
import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../../axiosInstance";
import Footer from "../Footer";
import { Header } from "../../Layout/Header";
import styles from "../login.module.css";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CookieConsent from "../../CookieConsent/CookieConsent";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Input from "@mui/material/Input";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function checkIsInFrame() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

const Login = (props) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const isInIframe = checkIsInFrame();
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageSocial, setErrorMessageSocial] = useState("");
  const { search } = useLocation();
  let query = useQuery();
  let l_redirecturl = query.get("redirecturl");
  const l_userIdFromQuery = query.get("userId");
  const l_siteIdFromQuery = query.get("siteId");

  const location = useLocation();
  const { userId, siteId } = location.state || {};
  // Use either query or state data based on preference
  const l_userId = l_userIdFromQuery || userId;
  const l_siteId = l_siteIdFromQuery || siteId;

  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies([
    "rp_access_token",
    "rp_refresh_token",
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.LINKEDIN_CLIENT_ID || "",
    scope: process.env.LINKEDIN_SCOPE || "",
    redirectUri: process.env.LINKEDIN_REDIRECT_URI || "",
    onSuccess: (code) => {
      onLoginViaLinkedIn(code);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [formData, setFormData] = React.useState({
    email: "",
    password: "",
  });

  const [formErrors, setFormErrors] = React.useState({
    email: "",
    password: "",
  });
  function isValidEmail(email) {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
  }
  function validateForm(formData) {
    try {
      // console.log("formData", formData);
      setFormErrors({ email: "", password: "" });

      if (formData.email === "") {
        setFormErrors((old) => {
          return { ...old, email: "Email can not be empty." };
        });
        return false;
      }

      if (!isValidEmail(formData.email)) {
        setFormErrors((old) => {
          return { ...old, email: "Invalid email address." };
        });
        return false;
      }

      if (formData.password === "") {
        setFormErrors((old) => {
          return { ...old, password: "Password is empty." };
        });
        return false;
      }
      return true;
    } catch (err) {
      return false;
    }
  }

  async function onLogin(e) {
    e.preventDefault();
    if (!validateForm(formData)) {
      return;
    }
    setIsLoading(true);
    try {
      let data = {
        email: formData.email,
        password: formData.password,
        rp_site_id: l_siteId,
        rp_user_id: l_userId,
      };

      let res = await axiosInstance.post("api/login", data);
      setIsLoading(false);

      // send reload message to parent
      if (isInIframe) {
        parent.postMessage("auth:reload", "*");
        return;
      }

      if (
        l_redirecturl !== undefined &&
        l_redirecturl !== "" &&
        l_redirecturl !== null
      ) {
        window.location.href = `${l_redirecturl}`;
      } else {
        navigate("/dashboard", {
          state: { token: res.data.token, user: res.data },
        });
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMessage(err.response.data.message);
    }
  }
  async function onLoginViaGoogle(googleCred) {
    setIsLoading(true);
    try {
      let data = {
        signupMode: "google",
        token: googleCred.credential,
        rp_user_id: l_userId,
        rp_site_id: l_siteId,
      };

      let res = await axiosInstance.post("api/signup", data, {
        withCredentials: true,
      });

      // console.log("on login", res);
      setIsLoading(false);
      // console.log('rp_site_id', cookies.rp_page_id)

      if (isInIframe) {
        parent.postMessage("auth:reload", "*");
        return;
      }

      if (
        l_redirecturl !== undefined &&
        l_redirecturl !== "" &&
        l_redirecturl !== null
      ) {
        window.location.href = `${l_redirecturl}`;
      } else {
        navigate("/dashboard", {
          state: { token: res.data.token, user: res.data },
        });
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMessageSocial(err.response.data.message);
    }
  }
  async function onLoginViaLinkedIn(authCode) {
    setIsLoading(true);
    try {
      let data = {
        signupMode: "linkedIn",
        authCode: authCode,
        rp_user_id: l_userId,
        rp_site_id: l_siteId,
      };

      let res = await axiosInstance.post("api/signup", data);
      setIsLoading(false);

      if (isInIframe) {
        parent.postMessage("auth:reload", "*");
        return;
      }

      if (
        l_redirecturl !== undefined &&
        l_redirecturl !== "" &&
        l_redirecturl !== null
      ) {
        window.location.href = `${l_redirecturl}`;
      } else {
        navigate("/dashboard", {
          state: { token: res.data.token, user: res.data },
        });
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMessageSocial(err.response.data.message);
    }
  }
  function handleChange(event) {
    setFormData((old) => {
      return { ...old, [event.target.id]: event.target.value.trim() };
    });
    setErrorMessage("");
    setErrorMessageSocial("");
  }

  return (
    <>
      <main className={`${styles.loginPage}`}>
        {isInIframe && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
            invisible={true}
          >
            <CircularProgress color="info" />
          </Backdrop>
        )}
        {!isInIframe && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Header />

        <section
          className={`${styles.contentContaienr} login-section flex justify-start`}
        >
          <div className={`${styles.leftContent} hidden md:inline-block`}>
            <img
              src={require("../../../images/login/bg-img.svg")}
              className="hidden xl:block"
            />
            <img
              src={require("../../../images/login/bg-img.svg")}
              className="small-img xl:hidden"
            />
          </div>
          <div
            className={`${styles.content} md:flex md:flex-col max-w-full ${
              isInIframe ? styles.nopadding : ""
            }`}
          >
            <div className={`${styles.formContainer}`}>
              <div>
                <h1
                  className={`${styles.loginHeading} mt-1 md:mt-8 lg:mt-16 mb-8`}
                >
                  Log into RocketPages
                </h1>
                <form className={`${styles.loginForm}`} onSubmit={onLogin}>
                  <div className={`${styles.emailRow} flex flex-col`}>
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      placeholder="Enter email address"
                      value={formData.email}
                      onChange={handleChange}
                      className={`${
                        formErrors.email !== "" ? "border-red-500" : ""
                      }`}
                    />
                    {formErrors.email !== "" && (
                      <label className={`${styles.errorLabels}`}>
                        {formErrors.email}
                      </label>
                    )}
                  </div>
                  <div className={`${styles.passwordRow} flex flex-col`}>
                    <label htmlFor="password">Password</label>

                    <FormControl sx={{ position: "relative" }}>
                      <Input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        placeholder="Enter password"
                        value={formData.password}
                        onChange={handleChange}
                        className={`${
                          formErrors.password !== "" ? "border-red-500" : ""
                        }`}
                        endAdornment={
                          <IconButton
                            className={`${styles.passwordEye}`}
                            sx={{
                              position: "absolute",
                              right: "20px",
                              width: "20px",
                              padding: "0px",
                              top: "13px",
                            }}
                            aria-label={
                              showPassword
                                ? "hide the password"
                                : "display the password"
                            }
                            onClick={handleClickShowPassword}
                            onMouseDown={(event) => event.preventDefault()}
                            onMouseUp={(event) => event.preventDefault()}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        }
                        sx={{
                          "&:before": { borderBottom: "none" }, // Removes the default bottom border
                          "&:after": { borderBottom: "none" }, // Removes focus border
                          "&:hover:not(.Mui-disabled):before": {
                            borderBottom: "none",
                          }, // Removes hover border
                          // Placeholder styling
                          "& input::placeholder": {
                            color: "#3B3B3B99",
                            fontWeight: "300",
                            opacity: 1,
                          },
                        }}
                      />
                    </FormControl>
                    {formErrors.password !== "" && (
                      <label className={`${styles.errorLabels}`}>
                        {formErrors.password}
                      </label>
                    )}
                  </div>
                  {errorMessage !== "" && (
                    <span className="flex text-center mb-2">
                      <ErrorOutlineIcon
                        fontSize="small"
                        sx={{ color: "red" }}
                      />
                      <label className={`${styles.errorLabelForm} `}>
                        {errorMessage}
                      </label>
                    </span>
                  )}
                  <button
                    type="submit"
                    className={`${styles.primaryBtn} ${styles.loginBtn}`}
                    // onClick={onLogin}
                  >
                    Login
                  </button>
                </form>
                {isInIframe ? (
                  <Link
                    to={{
                      pathname: "/forgot-password",
                      search: `?userId=${l_userId}&siteId=${l_siteId}`,
                    }}
                    className={`${styles.normalLink} inline-block my-1 text-[16px] font-semibold`}
                  >
                    Forgot password?
                  </Link>
                ) : (
                  <Link
                    to="/forgot-password"
                    className={`${styles.normalLink} inline-block my-1 text-[16px] font-semibold`}
                  >
                    Forgot password?
                  </Link>
                )}

                <div className={`${styles.seperator} flex items-center pt-8`}>
                  <div className={`${styles.seperatorLine}`}></div>
                  <div className={`${styles.seperatorText}`}>
                    Or Login with{" "}
                  </div>
                  <div className={`${styles.seperatorLine}`}></div>
                </div>
                <div
                  className={`${styles.socialSection} flex justify-center gap-8`}
                >
                  <GoogleLogin
                    onSuccess={async (credentialResponse) => {
                      onLoginViaGoogle(credentialResponse);
                    }}
                    onError={() => {
                      console.log("Login Failed");
                    }}
                    type="icon"
                  />

                  <div
                    className={`${styles.socialIcons}`}
                    onClick={linkedInLogin}
                  >
                    <img src={require("../../../images/login/linkedin.svg")} />
                  </div>
                </div>
                <div className="flex justify-center py-2">
                  {errorMessageSocial !== "" && (
                    <span className="flex text-center mb-2 items-center">
                      <ErrorOutlineIcon
                        fontSize="small"
                        sx={{ color: "red" }}
                      />
                      <label className={`${styles.errorLabelForm} `}>
                        {errorMessageSocial}
                      </label>
                    </span>
                  )}
                </div>
                <div className={`flex justify-center md:hidden`}>
                  <span
                    className={`${styles.dontHaveAccount} font-normal mr-1`}
                  >
                    Don&apos;t have an account?
                  </span>
                  <Link
                    to={`/signup${search}`}
                    className="text-sm text-[#243460] font-semibold"
                  >
                    Signup
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* {!isInIframe && <Footer />} */}
        <Footer />
      </main>
      {!isInIframe && <CookieConsent />}
    </>
  );
};

export default Login;
