import * as React from "react";
import { FC } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "../Auth/login.module.css";
import Divider from "@mui/material/Divider";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const Header: FC = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const pages: Array<keyof typeof pageRoutes> = [
    "TEMPLATES",
    "PRICING",
    "CONTACT",
    "SIGN UP",
  ];
  const pageRoutes = {
    TEMPLATES: "/choose-template",
    PRICING: "https://rocketpages.io/pricing",
    CONTACT: "https://rocketpages.io/contact",
    "SIGN UP": `/signup${search}`,
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handlePageNavigation = (page: keyof typeof pageRoutes) => {
    const route = pageRoutes[page];
    if (route.startsWith("http")) {
      window.open(route, "_blank"); // For external links
    } else {
      navigate(route); // For internal links
    }
    handleCloseNavMenu();
  };

  const checkIsInFrame = () => {
    try {
      return window.self !== window.top; // Returns true if in an iframe
    } catch (e) {
      return true; // Fallback in case of security restrictions
    }
  };

  const isLoginPage = pathname === "/login"; // Check if on the signup page

  const isInIframe = checkIsInFrame();
  let query = useQuery();
  let l_userId = query.get("userId");
  let l_siteId = query.get("siteId");

  return (
    <div className="flex items-center sm:justify-between justify-center p-2">
      <AppBar
        position="static"
        className={`bg-white flex justify-between items-center shadow-none px-0`}
      >
        <Container maxWidth="xl" className={`px-3`}>
          <Toolbar disableGutters className={`flex justify-between`}>
            <Link
              className={`${styles.logoLight} mr-2 md:mr-5 w-8 md:w-52`}
              to="/"
              onClick={(e) => {
                e.preventDefault();
                window.open("/", "_blank");
              }}
            >
              <img
                className="w-full hover:cursor-pointer hidden md:block"
                src={require("../../images/logo-light.svg")}
                alt="Log In"
              />
              <img
                className="w-full hover:cursor-pointer md:hidden"
                src={require("../../images/rocket-logo.svg")}
                alt="Log In"
              />
            </Link>
            {!isInIframe && (
              <Box
                sx={{
                  flexGrow: 1,
                  justifyContent: "flex-end",
                  display: { xs: "flex", lg: "none" },
                }}
              >
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  sx={{
                    color: "#243460",
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", lg: "none" },
                  }}
                  PaperProps={{
                    sx: {
                      backgroundColor: "#fff",
                      width: "336px",
                      height: "unset",
                      boxShadow: "1px 1px 12.3px 0px #00000040",
                      padding: "24px 8px",
                      top: "85px !important",
                      right: "0px !important",
                      left: "unset !important",
                    },
                  }}
                >
                  {pages.slice(0, 3).map((page) => (
                    <MenuItem
                      key={page}
                      onClick={() => handlePageNavigation(page)}
                      sx={{
                        backgroundColor: "transparent",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: "14px",
                          lineHeight: "28px",
                          color: "#243060",
                          fontWeight: 600,
                          "&:hover": {
                            color: "#FE7165",
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        {page}
                      </Typography>
                    </MenuItem>
                  ))}
                  <Divider
                    sx={{
                      margin: "30px 16px !important",
                    }}
                  />
                  <Box
                    sx={{
                      paddingX: "16px",
                    }}
                  >
                    {isInIframe ? (
                      <Link
                        to={{
                          pathname: isLoginPage ? `/signup` : "/login",
                          search: `?userId=${l_userId}&siteId=${l_siteId}`,
                        }}
                      >
                        <button className={`${styles.signUpText}`}>
                          {isLoginPage ? "SIGNUP" : "LOGIN"}
                        </button>
                      </Link>
                    ) : (
                      <Link to={isLoginPage ? `/signup${search}` : "/login"}>
                        <button className={`${styles.signUpText}`}>
                          {isLoginPage ? "SIGNUP" : "LOGIN"}
                        </button>
                      </Link>
                    )}
                  </Box>
                </Menu>
              </Box>
            )}

            <Box
              sx={{
                alignItems: "center",
                justifyContent: "center",
                gap: "54px",
                flexGrow: 1,
                display: { xs: "none", lg: "flex" },
              }}
            >
              {!isInIframe &&
                pages.slice(0, 3).map((page) => (
                  <MenuItem
                    key={page}
                    onClick={() => handlePageNavigation(page)}
                    sx={{
                      backgroundColor: "transparent",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: "14px",
                        lineHeight: "28px",
                        color: "#243060",
                        fontWeight: 600,
                        "&:hover": {
                          color: "#FE7165",
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      {page}
                    </Typography>
                  </MenuItem>
                ))}
            </Box>
            {/* responsive signup/login buttons */}
            <Box
              sx={{
                alignItems: "center",
                justifyContent: "center",
                gap: "16px",
                flexGrow: 0,
                marginRight: "4px",
                display: { xs: "none", lg: "flex" },
              }}
            >
              {isInIframe ? (
                <Link
                  to={{
                    pathname: isLoginPage ? `/signup` : "/login",
                    search: `?userId=${l_userId}&siteId=${l_siteId}`,
                  }}
                >
                  <button className={`${styles.signUpText}`}>
                    {isLoginPage ? "SIGNUP" : "LOGIN"}
                  </button>
                </Link>
              ) : (
                <Link to={isLoginPage ? `/signup${search}` : "/login"}>
                  <button className={`${styles.signUpText}`}>
                    {isLoginPage ? "SIGNUP" : "LOGIN"}
                  </button>
                </Link>
              )}
            </Box>
            {/* responsive getting started and signup/login buttons */}
            <Box
              sx={{
                flexGrow: 0,
                display: "flex",
                alignItems: "center",
                gap: "25px",
              }}
            >
              {isInIframe ? (
                <Link to={isLoginPage ? `/signup${search}` : `/login${search}`}>
                  <button
                    className={`${styles.primaryBtn} ${styles.signUpBtn}`}
                  >
                    {isLoginPage ? "SIGNUP" : "LOGIN"}
                  </button>
                </Link>
              ) : (
                <button
                  className={`${styles.primaryBtn} ${styles.signUpBtn}`}
                  onClick={() => {
                    navigate(`/choose-template`);
                  }}
                >
                  GET STARTED
                </button>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};
