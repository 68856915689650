import * as React from "react";
import { useEffect } from "react";
import styles from "../login.module.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Footer from "../Footer";
import { Header } from "../../Layout/Header";
import { useCookies } from "react-cookie";
import axiosInstance from "../../../axiosInstance";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CookieConsent from "../../CookieConsent/CookieConsent";
import { useSnackbar } from "notistack";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Input from "@mui/material/Input";

const ResetPassword = () => {
  const [errorMessage, setErrorMessage] = React.useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const [cookies, setCookie] = useCookies(["rp_access_token"]);
  const [isLoading, setIsLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [formData, setFormData] = React.useState({
    password: "",
    rePassword: "",
  });
  const [formErrors, setFormErrors] = React.useState({
    password: "",
    rePassword: "",
  });
  const isFormInvalid =
    formErrors.password !== "" ||
    formErrors.rePassword !== "" ||
    formData.password === "" ||
    formData.rePassword === "";

  function validateForm(formData) {
    try {
      setFormErrors({ password: "", rePassword: "" });
      if (formData.password === "") {
        setFormErrors((old) => {
          return { ...old, password: "New password can not be empty" };
        });
        return false;
      }

      if (formData.rePassword === "") {
        setFormErrors((old) => {
          return { ...old, rePassword: "Confirm password can not be empty" };
        });
        return false;
      }

      if (formData.password.length < 8) {
        setFormErrors((old) => {
          return {
            ...old,
            password: "New password length can not be less than 8 chars.",
          };
        });
        return false;
      }

      if (formData.rePassword.length < 8) {
        setFormErrors((old) => {
          return {
            ...old,
            rePassword: "Confirm password length can not be less than 8 chars.",
          };
        });
        return false;
      }

      if (formData.rePassword.length < 8) {
        setFormErrors((old) => {
          return {
            ...old,
            rePassword: "Confirm password length can not be less than 8 chars.",
          };
        });
        return false;
      }

      if (formData.password !== formData.rePassword) {
        setFormErrors((old) => {
          return {
            ...old,
            rePassword: "Confirm password does not match password.",
          };
        });
        return false;
      }
      return true;
    } catch (err) {
      return false;
    }
  }
  async function onUpdatePassword(e, formData) {
    e.preventDefault();
    if (!validateForm(formData)) {
      return;
    }
    setIsLoading(true);

    let accessToken = cookies.rp_access_token || token;
    try {
      let data = {
        password: formData.password,
      };

      let res = await axiosInstance.post("api/update-password", data, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      setIsLoading(true);
      enqueueSnackbar("Password has been updated! Please login again", {
        variant: "info",
        className: "bg-grey-400",
      });
      navigate("/login", { state: { token: res.data.token } });
    } catch (err) {
      setErrorMessage(err.message);
      setIsLoading(false);
    }
  }
  function handleChange(event) {
    setFormData((old) => {
      return { ...old, [event.target.id]: event.target.value.trim() };
    });
    setFormErrors({ password: "", rePassword: "" });
    setErrorMessage("");
  }

  const [showPassword, setShowPassword] = React.useState(false);
  const [showRePassword, setShowRePassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowRePassword = () => setShowRePassword((show) => !show);

  useEffect(() => {
    // Redirect if user is already logged in
    if (cookies.rp_loggedin) {
      navigate("/choose-template"); // Redirect to the dashboard
    }
  }, [cookies.rp_loggedin, navigate]);

  return (
    <>
      <main className={`${styles.forgotPassword}`}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Header />

        <section className={`${styles.contentContaienr} flex justify-start`}>
          <div className={`${styles.leftContent} hidden md:inline-block`}>
            <img
              src={require("../../../images/login/bg-img.svg")}
              className="hidden xl:block"
            />
            <img
              src={require("../../../images/login/bg-img.svg")}
              className="small-img xl:hidden"
            />
          </div>
          <div className={`${styles.content} md:flex md:flex-col`}>
            <div className={`${styles.formContainer}`}>
              <h1
                className={`${styles.loginHeading} mt-1 md:mt-8 lg:mt-16 mb-8`}
              >
                Reset password{" "}
              </h1>
              <p className="text-base font-normal max-w-80">
                Enter a new password for your account xyz123@email.com.
              </p>
              <div>
                <form
                  className={`${styles.loginForm}`}
                  onSubmit={(e) => {
                    onUpdatePassword(e, formData);
                  }}
                >
                  <div className={`${styles.emailRow} flex flex-col`}>
                    <label htmlFor="email">Password</label>

                    <FormControl sx={{ position: "relative" }}>
                      <Input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        placeholder="Enter password"
                        value={formData.password}
                        onChange={handleChange}
                        className={`${
                          formErrors.password !== "" ? "border-red-500" : ""
                        }`}
                        endAdornment={
                          <IconButton
                            className={`${styles.passwordEye}`}
                            sx={{
                              position: "absolute",
                              right: "20px",
                              width: "20px",
                              padding: "0px",
                              top: "13px",
                            }}
                            aria-label={
                              showPassword
                                ? "hide the password"
                                : "display the password"
                            }
                            onClick={handleClickShowPassword}
                            onMouseDown={(event) => event.preventDefault()}
                            onMouseUp={(event) => event.preventDefault()}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        }
                        sx={{
                          "&:before": { borderBottom: "none" }, // Removes the default bottom border
                          "&:after": { borderBottom: "none" }, // Removes focus border
                          "&:hover:not(.Mui-disabled):before": {
                            borderBottom: "none",
                          }, // Removes hover border
                          // Placeholder styling
                          "& input::placeholder": {
                            color: "#3B3B3B99",
                            fontWeight: "300",
                            opacity: 1,
                          },
                        }}
                      />
                    </FormControl>
                    {formErrors.password !== "" && (
                      <label className={`${styles.errorLabels}`}>
                        {formErrors.password}
                      </label>
                    )}
                  </div>
                  <div className={`${styles.emailRow} flex flex-col`}>
                    <label htmlFor="email">Confirm password</label>

                    <FormControl sx={{ position: "relative" }}>
                      <Input
                        type={showRePassword ? "text" : "password"}
                        id="rePassword"
                        placeholder="Enter password"
                        value={formData.rePassword}
                        onChange={handleChange}
                        className={`${
                          formErrors.rePassword !== "" ? "border-red-500" : ""
                        }`}
                        endAdornment={
                          <IconButton
                            className={`${styles.passwordEye}`}
                            sx={{
                              position: "absolute",
                              right: "20px",
                              width: "20px",
                              padding: "0px",
                              top: "13px",
                            }}
                            aria-label={
                              showRePassword
                                ? "hide the password"
                                : "display the password"
                            }
                            onClick={handleClickShowRePassword}
                            onMouseDown={(event) => event.preventDefault()}
                            onMouseUp={(event) => event.preventDefault()}
                            edge="end"
                          >
                            {showRePassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        }
                        sx={{
                          "&:before": { borderBottom: "none" }, // Removes the default bottom border
                          "&:after": { borderBottom: "none" }, // Removes focus border
                          "&:hover:not(.Mui-disabled):before": {
                            borderBottom: "none",
                          }, // Removes hover border
                          // Placeholder styling
                          "& input::placeholder": {
                            color: "#3B3B3B99",
                            fontWeight: "300",
                            opacity: 1,
                          },
                        }}
                      />
                    </FormControl>
                    {formErrors.rePassword !== "" && (
                      <label className={`${styles.errorLabels}`}>
                        {formErrors.rePassword}
                      </label>
                    )}
                  </div>

                  {errorMessage !== "" && (
                    <span className="flex text-center mb-2">
                      <ErrorOutlineIcon
                        fontSize="small"
                        sx={{ color: "red" }}
                      />
                      <label className={`${styles.errorLabelForm} `}>
                        {errorMessage}
                      </label>
                    </span>
                  )}

                  <button
                    // onClick={onUpdatePassword}
                    className={`${styles.primaryBtn} ${styles.loginBtn} ${
                      isFormInvalid ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    disabled={isFormInvalid}
                  >
                    RESET PASSWORD
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </main>
      <CookieConsent />
    </>
  );
};

export default ResetPassword;
